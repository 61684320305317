export const patientSteps = {
  stepsEnabled: true,
  initialStep: 0,
  steps: [
    {
      element: '.firstStep',
      intro:
        '<h4 class="text-center">Listado de pacientes</h4><br><p class="h5">Aquí podrás ver, crear y editar todos los pacientes que hayas creado.</p> ',
      tooltipClass: 'onboarding-steps',
    },
    {
      element: '.secondStep',
      intro:
        '<h4 class="text-center">Listado de pacientes</h4><br><p class="h5">A medida que vayas agregando pacientes se irán incorporando a esta lista.</p> ',
      tooltipClass: 'onboarding-steps',
    },
  ],
};
