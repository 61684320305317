import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import React, { useEffect, useState } from 'react';
import './Appointment.css';
import './Calendar.css';
import { render } from 'react-dom';
import 'rsuite/dist/styles/rsuite-default.css';
import formatter from '../../utils/formatter';
import { Calendar, Badge, Popover, Whisper, Tooltip } from 'rsuite/es/index';
import { IntlProvider } from 'rsuite';
import locale_es from './locale_es';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import isMobile from '../../utils/isMobile';
import { Steps } from 'intro.js-react';
import { appointmentSteps } from './AppointmentSteps';
import '../common/Steps.css';
import 'intro.js/introjs.css';
import { stepsStorage, STEPS_SECTIONS } from '../common/Steps';
import Swal from 'sweetalert2';
var moment = require('moment');

const STEPS_SECTION = STEPS_SECTIONS.APPOINTMENT; // onboarding section ID

const Appointment = props => {
  const download = React.createRef();

  const [appointments, setAppointments] = useState([]);
  const [patientCount, setPatientCount] = useState(null);
  const [CSVdata, setCSVdata] = useState('');

  useEffect(() => {
    getAppointments();
    exportAppointments();
  }, []);

  const getAppointments = async () => {
    try {
      let appoint = await apiHelper.getAppointments();
      setAppointments(appoint.data);
      let patientCountResponse = await apiHelper.getPatientsCount();
      setPatientCount(patientCountResponse.data.count);
    } catch (e) {
      console.log('Error loading appointments');
    }
  };

  const exportAppointments = async () => {
    try {
      const response = await apiHelper.getExportAppointments();
      setCSVdata(response.data);
      download.current.link.click();
    } catch (e) {
      console.log('Error loading report');
    }
  };

  const getTodayAppointments = date => {
    let formattedDate = formatter.formatDate(date);
    let filteredAppointments = appointments
      .filter(appointment => {
        let appointmentDate = formatter.formatDate(appointment.appointmentDate);
        return appointmentDate == formattedDate;
      })
      .sort((a, b) => {
        if (a.startTime < b.startTime) {
          return -1;
        }
        if (a.startTime > b.startTime) {
          return 1;
        }
        return 0;
      });

    return filteredAppointments;
  };

  const formatDate = (data, formatStr) => {
    return format(data, formatStr, { locale: es });
  };

  const deleteAppointment = async appointment => {
    Swal.fire({
      title: `¿Desea eliminar el turno?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
      heightAuto: false,
    }).then(async result => {
      if (result.isConfirmed) {
        await apiHelper.deleteAppointment(appointment.id);
        await getAppointments();
        Swal.fire({
          title: `Turno eliminado`,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Cerrar',
          heightAuto: false,
          customClass: {
            cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
          },
        });
      }
    });
  };

  const renderCell = date => {
    const appointments = getTodayAppointments(date);

    let list = [];
    const ITEMS_PER_CELL = 3;
    const MAX_CHARACTERS = 14;
    for (let appointment of appointments) {
      let fullname = `${appointment.patient.firstName} ${appointment.patient.lastName}`;
      list.push({
        id: appointment.id,
        title:
          appointment.startTime +
          ' ' +
          (fullname.length > MAX_CHARACTERS
            ? fullname.substring(0, MAX_CHARACTERS - 1) + '...'
            : fullname),
        tooltip: <Tooltip>{`${appointment.startTime} - ${fullname}`}</Tooltip>,
      });
    }

    const displayList = list.filter(
      (item, index) => index < (isMobile ? 0 : ITEMS_PER_CELL),
    );

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <li>
          <Whisper
            placement="right"
            trigger="click"
            speaker={
              <Popover className="theSize">
                {list.map((item, index) => {
                  let event;
                  if (formatter.isSameDayOrAfter(date)) {
                    event = (
                      <Link
                        to={`${process.env.PUBLIC_URL}/appointment/${item.id}`}>
                        <Badge />
                        {item.title.substring(0, 40)}
                      </Link>
                    );
                  } else {
                    event = (
                      <div>
                        <Badge />
                        {item.title.substring(0, 40)}
                      </div>
                    );
                  }
                  return <p key={index}>{event}</p>;
                })}
              </Popover>
            }>
            <a>
              {moreCount} {list.length < 2 ? 'Evento' : 'Eventos'}
            </a>
          </Whisper>
        </li>
      );

      const startVideoCall = appointmentId => {
        apiHelper
          .getAppointment(appointmentId)
          .then(appointmentResponse => {
            var win = window.open(
              `${appointmentResponse.data.meetingUrl}?jwt=${appointmentResponse.data.meetingJwt}`,
              '_blank',
            );
            win.focus();
          })
          .catch(e => {
            console.error(e);
          });
      };

      const sendAppointmentEmailReminder = appointmentId => {
        apiHelper
          .getAppointmentReminder(appointmentId)
          .then(resp => {
            return Swal.fire({
              title: 'Email de recordatorio enviado',
              icon: 'success',
              confirmButtonColor: '#22654e',
              confirmButtonText: 'Continuar',
            });
          })
          .catch(e => {
            console.error(e);
          });
      };

      return (
        <div className="appointments-container">
          {displayList.map((item, index) => {
            let appointment = appointments.find(
              appointmentElement => appointmentElement.id == item.id,
            );
            let popOverContent = (
              <div className="d-flex flex-column appointment-popover">
                <div className="d-flex flex-row justify-content-end mt-1">
                  {/* buttons */}
                  {appointment.hash && (
                    <a
                      title="Iniciar videollamada"
                      href="#"
                      onClick={() => {
                        startVideoCall(appointment.id);
                        return false;
                      }}>
                      <i
                        className={`fas fa-play-circle appointment-popover-button ml-3`}
                      />
                    </a>
                  )}
                  <Link
                    title="Enviar recordatorio"
                    onClick={() => {
                      sendAppointmentEmailReminder(appointment.id);
                    }}>
                    <i
                      className={`fas fa-envelope appointment-popover-button ml-3`}
                    />
                  </Link>
                  <Link
                    title="Editar turno"
                    to={`${process.env.PUBLIC_URL}/appointment/${appointment.id}`}>
                    <i
                      className={`fas fa-pen-square appointment-popover-button ml-3`}
                    />
                  </Link>
                  <Link
                    title="Eliminar turno"
                    onClick={() => {
                      deleteAppointment(appointment);
                    }}>
                    <i
                      className={`fas fa-trash appointment-popover-button-delete ml-3`}
                    />
                  </Link>
                </div>

                <div className="d-flex flex-row">
                  {/* name */}
                  <p className="appointment-popover-name mx-3 mb-3">
                    {`${appointment.patient.firstName} ${appointment.patient.lastName}`}
                  </p>
                </div>

                <div className="d-flex flex-row">
                  {/* fecha */}
                  <i
                    className={`fas fa-calendar appointment-popover-icons mx-3`}
                  />
                  <p className="appointment-popover-item mb-2">
                    {`${moment(appointment.appointmentDate).format(
                      'dddd, LL',
                    )}`}
                  </p>
                </div>

                <div className="d-flex flex-row">
                  {/* hora */}
                  <i
                    className={`fas fa-clock appointment-popover-icons mx-3`}
                  />
                  <p className="appointment-popover-item mb-2">
                    {`De ${appointment.startTime} a ${appointment.endTime}`}
                  </p>
                </div>

                <div className="d-flex flex-row">
                  {/* nota */}
                  <i
                    className={`fas fa-sticky-note appointment-popover-icons mx-3`}
                  />
                  <p className="appointment-popover-item mb-2">
                    {`${appointment.description}`}
                  </p>
                </div>

                <div className="d-flex flex-row justify-content-end mt-auto">
                  {/* ver paciente */}
                  <Link
                    to={`${process.env.PUBLIC_URL}/patient/${appointment.patient.id}/records`}>
                    Ver ficha del paciente
                  </Link>
                </div>
              </div>
            );
            return (
              <div
                key={index}
                className={`appointment-item ${
                  appointment.hash
                    ? 'appointment-item-online'
                    : 'appointment-item-in-person'
                }`}>
                <Whisper
                  placement="right"
                  trigger="click"
                  speaker={<Popover className="">{popOverContent}</Popover>}>
                  <a>{item.title}</a>
                </Whisper>
              </div>
            );
          })}
          {moreCount ? moreItem : null}
        </div>
      );

      return null;
    }
  };

  // OnBoarding
  const onExit = () => {
    stepsStorage.setDone(STEPS_SECTION);
  };

  return (
    <>
      <Steps
        enabled={!stepsStorage.isDone(STEPS_SECTION)}
        steps={appointmentSteps.steps}
        initialStep={appointmentSteps.initialStep}
        options={{
          nextLabel: 'Siguiente',
          prevLabel: 'Anterior',
          skipLabel: 'Saltar',
          doneLabel: 'Finalizar',
          showStepNumbers: true,
        }}
        onExit={onExit}
      />

      <div className={'d-flex flex-column h-100 ' + props.className}>
        {/* NEW PATIENT BANNER */}
        <div className="container">
          {patientCount === 0 && (
            <div className="row">
              <div className="col">
                <div className="add-patient-banner my-2">
                  {/* banner content */}
                  <div className="container h-100">
                    <div className="row h-100 align-items-center px-3">
                      <div className="col-1 mx-3 add-patient-banner-icon">
                        <i className="fas fa-user-plus"></i>
                      </div>
                      <div className="col">
                        <div className="row">
                          <p className="text-white add-patient-banner-text mb-0">
                            <span className="font-weight-bold">¡Recordá</span>{' '}
                            que para agendar un turno{' '}
                            <span className="font-weight-bold">
                              el paciente debe estar ingresado!
                            </span>
                          </p>
                        </div>
                        <div className="row">
                          <Link
                            to={`${process.env.PUBLIC_URL}/patient/new`}
                            className="pl-1">
                            <span className="add-patient-banner-link">
                              AGREGAR PACIENTE
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="d-flex flex-row-reverse my-1">
            <div className="">
              <Link
                to={`${process.env.PUBLIC_URL}/appointment/new`}
                className="px-1 my-1">
                <button
                  type="button"
                  className="fourthStep h-100 btn button-appointment mr-2">
                  <i className="fas fa-plus-circle text-white md-hide"></i>{' '}
                  Agendar Consulta
                </button>
              </Link>
            </div>
          </div>

          <div className="mt-2">
            <IntlProvider locale={locale_es} formatDate={formatDate}>
              <Calendar renderCell={renderCell} onSelect={null} />
            </IntlProvider>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Appointment;
