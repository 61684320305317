export const appointmentSteps = {
  stepsEnabled: true,
  initialStep: 0,
  steps: [
    {
      element: '.firstStep',
      intro:
        '<h4 class="text-center">¡Bienvenido a la Agenda!</h4><br><p class="h5">Aquí podrás ver el calendario con los turnos que hayas programado.</p> ',
      tooltipClass: 'onboarding-steps',
    },
    {
      element: '.secondStep',
      intro:
        '<h4 class="text-center">Calendario</h4><br><p class="h5">Los turnos programados van a figurar en esta sección. En la parte superior del calendario podrás navegar por mes (izquierda) y volver al día de Hoy (derecha). </p> ',
      tooltipClass: 'onboarding-steps',
    },
    {
      element: '.thirdStep',
      intro:
        '<h4 class="text-center">Nuevo Paciente</h4><br><p class="h5">Antes de crear el turno asegurate de haber creado al paciente.</p> ',
      tooltipClass: 'onboarding-steps',
    },
    {
      element: '.fourthStep',
      intro:
        '<h4 class="text-center">Nuevo Turno</h4><br><p class="h5">Una vez creado el paciente podrás agendarle un turno con este botón.</p> ',
      tooltipClass: 'onboarding-steps',
    },
  ],
};
export const appointmentDetailSteps = {
  stepsEnabled: true,
  initialStep: 0,
  steps: [
    {
      element: '.firstStep',
      intro:
        '<h4 class="text-center">Creación de turno</h4><br><p class="h5">En esta sección podrás programar tus turnos.</p> ',
      tooltipClass: 'onboarding-steps',
    },
    {
      element: '.secondStep',
      intro:
        '<h4 class="text-center">Paciente</h4><br><p class="h5">El paciente debe ser creado de antemano si no figura. Se le enviará un link al mail una vez creado el turno.</p> ',
      tooltipClass: 'onboarding-steps',
    },
  ],
};
